@import "~normalize.css/normalize.css";

// BluePrint JS
@import "~@blueprintjs/core/lib/scss/variables";
@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";
@import "~@blueprintjs/datetime/lib/css/blueprint-datetime.css";
@import "~@blueprintjs/select/lib/css/blueprint-select.css";

// Bulma CSS: https://bulma.io
@import "~bulma/sass/utilities/_all.sass";
@import "~bulma/sass/base/helpers.sass";
@import "~bulma/sass/components/navbar.sass";
@import "~bulma/sass/grid/columns.sass";


// Augment BulmaCSS `navbar` styles
.navbar {
  width: 100%;

  .navbar-item {
    // Firefox shows a blue outline around anchor tags when clicked!
    a:focus {
      outline: none;
    }

    // Menu doesn't close after clicking item in Safari, Firefox, Chrome-Mac
    &.is-hoverable:not(:hover) .navbar-dropdown:focus-within {
      display: none;
    }
  }
}

.app {
  display: flex;
  flex-direction: column;
}

.app .pageContent {
  flex: 1;
}

@media screen {
 body {
     // Re: https://blueprintjs.com/docs/#core/components/portal
  width: 100%;
  position: absolute;
 }

 .app {
  height: 100vh;
 }

 .app .pageContent {
    overflow: auto;
    min-height: 400px;
  }
}


@media print {
  .pageBreakBefore {
    break-before: page;
  }

  .pageBreakAfter {
    break-after: page;
  }

  .#{$ns}-form-group {
    break-inside: avoid-page;
  }
}



 // Turn off the spinner control on input[type="number"] fields.
 // Ref: https://stackoverflow.com/questions/3790935/can-i-hide-the-html5-number-input-s-spin-box
 input[type="number"]::-webkit-outer-spin-button,
 input[type="number"]::-webkit-inner-spin-button {
     -webkit-appearance: none;
     margin: 0;
 }
 input[type="number"] {
     -moz-appearance: textfield;
 }



footer.pageFooter {
  padding: 4px 10px;
  border-top: 1px solid #ccc;
}



 h1, h2, h3, h4, h5, h6 {
   font-weight: normal;
  //  opacity: 0.9;
  //  text-shadow: 1px 1px #eee;
 }


// @mixin intent($bpIntent) {
//   span.#{$ns}-intent-#{$bpIntent}  {
//     color: $pt-intent-#{$bpIntent};
//   }
// }

// Used to highlight specific form elements in a different color
.formCallout, .formCalloutXSmall {
  color: $blue3;
}

.formCalloutXSmall {
  font-size: x-small;
}

.intent-primary {
  //@include intent(danger);
  color: $pt-intent-primary;
}

.intent-success {
  //@include intent(success);
  color: $pt-intent-success;
}

.intent-warning {
  //@include intent(success);
  color: $pt-intent-warning;
}

.intent-danger {
  //@include intent(danger);
  color: $pt-intent-danger;
}


// $intents: "danger", "success";
// @each $intent in $intents {
//   span.#{$ns}-intent-#{$intent} {
//     color: $pt-intent-#{$intent};
//   }
// }

.bordered {
  border: solid 1px $pt-divider-black;
  // &:hover {
  //   box-shadow: $pt-elevation-shadow-2;
  //   border: unset;
  // }
}

.elevated {
  box-shadow: $pt-elevation-shadow-2;
}


// .column {
//   border: dashed 1px greenyellow;
// }


// .bp3-running-text {
//   h1, h2, h3 {
//     margin-top: 10px;
//     margin-bottom: 10px;
//   }
// }


// Give buttons inside BP dialogs actions area a min-width
.#{$ns}-dialog-footer-actions {
  button.#{$ns}-button {
      min-width: 80px;
      margin-left: 10px;
      margin-right: 10px;
  }
}

// Change default BP readonly input style
.#{$ns}-input-group {
  input[type="text"][readonly] {
    color: $pt-text-color-disabled;
  }
}

// Highlight error on Blueprint/select::button elements
.#{$ns}-popover-target {
  button.error {
    box-shadow: inset 0 0 0 1px $pt-intent-danger;
  }
}

// Hightlight error on Blueprint checkbox/switch components
.#{$ns}-control.#{$ns}-switch.error, .#{$ns}-control.#{$ns}-checkbox.error {
  .#{$ns}-control-indicator {
    background-color: $pt-intent-danger;
  }
}


// ----------- Flex alignments -------

// Notes:
// When flex-direction = 'row' (default):
// - justifyContent = center, performs horizontal centering
// - alignItems = center, performs vertical centering
// When flex-direction = 'column':
// - justifyContent = center, performs vertical centering.
// - alignItems = center performs horizontal centering
.alignLeft
{
  display: flex;
  justify-content: flex-start;
}

.alignTop {
  display: flex;
  align-items: flex-start;
}

.alignCenter
{
  display: flex;
  justify-content: center;
}

.alignMiddle {
  display: flex;
  align-items: center;
}

.alignRight
{
  display: flex;
  justify-content: flex-end;
}

.alignBottom {
  display: flex;
  align-items: flex-end;
}

.centered {
  // Vertically and horizontally center children
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center
 }
